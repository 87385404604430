<template>
  <section class="p-2">
    <h2>{{ $t('service.helicopter.title') }}</h2>
    <p>{{ $t('service.helicopter.introduction') }}</p>

    <!-- TABLE TOP -->
    <app-data-table-top
      table-name="helicopters"
      class="mb-1 mt-3"
      condensed="lg"
      size="md"
      :show-add="$route.name !== 'productShow' && $route.name !== 'operatorProductShow'"
      :per-page="perPage"
      @table-select-per-page=";(perPage = $event), refreshHelicoptersTable()"
      @table-add="addForm = 'add'"
    />

    <b-table
      ref="helicopterListTable"
      class="w-100"
      :items="fetchHelicopters"
      :fields="fields"
      primary-key="id"
      show-empty
      responsive
      striped
      :empty-text="$t('common.no_record_found')"
    >
      <!-- CELL -->
      <template #cell(modelRegistration)="{ item }">
        {{ `${item.organizationHelicopter.modelType} - ${item.organizationHelicopter.registration}` }}
      </template>
      <template #cell(active)="{ item }">
        <b-form-checkbox :checked="item.active" name="helico-active" switch inline disabled />
      </template>

      <template #cell(actions)="data">
        <div align="right">
          <app-data-table-actions
            :table-name="`Helicopters-${data.item.id}`"
            display-mode="inline-icon"
            :show-add="false"
            :show-duplicate="false"
            :show-show="false"
            :show-edit="$can('SERVICE_EDIT')"
            :show-delete="$can('SERVICE_EDIT')"
            @edit="editHelicopter(data.item)"
            @delete="removeHelicopter(data.item.id)"
          />
        </div>
      </template>
    </b-table>

    <!--PAGINATION-->
    <app-data-table-bottom
      class="m-2"
      table-name="Helicopters"
      :page="page"
      :per-page="perPage"
      :total-count="dataMeta.totalItems"
      @pagination="page = $event"
    />

    <helicopter-management-form v-model="addForm" :helicopter="selectedHelicopter" @refresh-helicopters-table="refreshHelicoptersTable()" />

    <footer-form-service class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />
  </section>
</template>

<script>
import { defineComponent, inject, ref } from '@vue/composition-api'
import AppDataTableTop from '@/components/AppDataTableTop.vue'
import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import HelicopterManagementForm from '../component/HelicopterManagementForm.vue'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'HelicopterManagement',

  components: {
    HelicopterManagementForm,
    AppDataTableTop,
    AppDataTableBottom,
    FooterFormService,
    AppDataTableActions,
  },

  setup(_props, ctx) {
    const { $i18n, $bvModal, alert } = ctx.root

    const product = inject('product')

    const addForm = ref(null)
    const helicopterListTable = ref(null)
    const selectedHelicopter = ref(null)

    const refreshHelicoptersTable = () => {
      helicopterListTable.value.refresh()
    }

    const dataMeta = ref({
      totalItems: 0,
      firstPage: 1,
      previousPage: null,
      nextPage: null,
      lastPage: null,
    })

    const page = ref(1)
    const perPage = ref(10)
    let sort = {}

    const fields = [
      { key: 'modelRegistration', label: $i18n.t('service.helicopter.model_registration'), thStyle: 'width: 100%' },
      { key: 'active', label: $i18n.t('common.active') },
      { key: 'actions', label: $i18n.tc('action.title', 2) },
    ]

    const fetchHelicopters = (btable, callback) => {
      if (btable.sortBy) sort = { [btable.sortBy]: btable.sortDesc ? 'desc' : 'asc' }

      const params = {
        page: page.value > 1 ? page.value : null,
        numberOfItemsPerPage: perPage.value !== 10 ? perPage.value : null,
        ...sort,
      }
      product.value.fetchHelicopters(params, ctx.root.$route.params.service_id, ctx.root.$route.query.vid).then(res => {
        dataMeta.value = res

        if (callback) {
          callback(product.value.helicopters)
        }
      })
    }

    const editHelicopter = helicopter => {
      addForm.value = 'edit'
      selectedHelicopter.value = helicopter
    }

    const removeHelicopter = id => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async isConfirmed => {
          if (isConfirmed) {
            await product.value.deleteHelicopter(id)
            alert($i18n.t('alert.delete.success'))
            refreshHelicoptersTable()
          }
        })
    }

    return {
      product,
      addForm,
      fields,
      helicopterListTable,
      refreshHelicoptersTable,
      fetchHelicopters,
      selectedHelicopter,
      page,
      perPage,
      dataMeta,
      editHelicopter,
      removeHelicopter,
    }
  },
})
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
[dir] .form-label-group {
  margin-bottom: 0;
}
</style>
