<template>
  <b-sidebar id="sidebar-helicopter" no-header :visible="!!value" @hidden="$emit('input', null)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t(`service.helicopter.${value}_an_helicopter`) }}</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="helicopterFormRef" tag="form" class="p-5" @submit.prevent="onSubmit">
        <validation-provider #default="{ errors }" :name="$t('service.helicopter.model_registration')" rules="required" class="validation-required">
          <b-form-group :label="$t('service.helicopter.model_registration')" label-for="organizationHelicopter">
            <v-select
              v-model="selectedHelicopter.organizationHelicopter"
              input-id="organizationHelicopter"
              :placeholder="$t('service.helicopter.model_registration')"
              label="id"
              :options="helicoOptions"
              :disabled="value === 'edit'"
              @open.once="fetchOrganizationHelicopters(selectedOrganization)"
            >
              <template #selected-option="{ modelType, registration }"> {{ modelType }} - {{ registration }} </template>
              <template #option="{ modelType, registration }"> {{ modelType }} - {{ registration }} </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-form-checkbox v-model="selectedHelicopter.active" name="helicopter-active" switch nline>
          {{ $t('common.active') }}
        </b-form-checkbox>

        <!-- submit and reset -->
        <app-form-actions class="mt-5" form-name="deposit-zone-form" @cancel="hide" @reset="selectedHelicopter = _cloneDeep(initForm)" />
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from '@vue/composition-api'

import AppFormActions from '@/components/AppFormActions.vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'HelicopterManagementForm',

  components: {
    AppFormActions,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    helicopter: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },

  setup(props, ctx) {
    const { $i18n, toaster, $store, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const product = inject('product')

    const helicopterFormRef = ref(null)
    const helicopterLoading = ref(false)

    const selectedHelicopter = ref({
      id: null,
      organizationHelicopter: null,
      active: true,
    })
    const emptyForm = _cloneDeep(selectedHelicopter.value)
    const initForm = ref(_cloneDeep(selectedHelicopter.value))

    const helicoptersId = computed(() => {
      if (!product.value.helicopters.map) return []
      return product.value.helicopters.map(helico => helico.organizationHelicopter.id)
    })

    // Without already in product
    const helicoOptions = computed(() => {
      if (!$store.state.organizationHelicopter.organizationHelicopters) return []
      return $store.state.organizationHelicopter.organizationHelicopters.filter(helico => !helicoptersId.value.includes(helico.id))
    })

    watch(
      () => props.value,
      value => {
        if (value === 'edit') {
          selectedHelicopter.value = _cloneDeep(props.helicopter)
          initForm.value = _cloneDeep(props.helicopter)
        } else if (!value) {
          helicopterFormRef.value.reset()
          selectedHelicopter.value = _cloneDeep(emptyForm)
          initForm.value = _cloneDeep(emptyForm)
        }
      },
    )

    const onSubmit = async () => {
      await helicopterFormRef.value.validate().then(validForm => {
        if (validForm) {
          helicopterLoading.value = true
          const action = props.value === 'add' ? product.value.postHelicopter : product.value.patchHelicopter

          action(selectedHelicopter.value)
            .then(() => {
              $emit('refresh-helicopters-table')
              $emit('input', null)
            })
            .finally(() => {
              helicopterLoading.value = false
            })
        } else {
          toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    }

    return {
      product,
      initForm,
      helicopterFormRef,
      helicoptersId,
      helicoOptions,
      selectedHelicopter,
      helicopterLoading,
      onSubmit,
    }
  },

  methods: {
    ...mapActions('organizationHelicopter', ['fetchOrganizationHelicopters']),
  },
})
</script>
